import { Slick } from "../slick";

class Amenities {
	constructor() {
		this.init();
	}

	init() {

		const slickShow = new Slick("[data-slider='show']", {
			adaptiveHeight: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			centerMode: true,
			centerPadding: "250px",
			dots: false,
			prevArrow: document.querySelector(".amenities-gallery__slide--prev"),
			nextArrow: document.querySelector(".amenities-gallery__slide--next"),
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						adaptiveHeight: true,
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						centerMode: true,
						centerPadding: "250px",
						dots: false,
						prevArrow: document.querySelector(
							".amenities-gallery__slide--prev"
						),
						nextArrow: document.querySelector(
							".amenities-gallery__slide--next"
						),
					},
				},
				{
					breakpoint: 901,
					settings: {
						adaptiveHeight: true,
						infinite: true,
						dots: true,
						slidesToShow: 1,
						centerMode: false,
						slidesToScroll: 1,
					},
				},
			],
		});

		jQuery('[data-slider="features"]').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			infinite: true,
			arrows: false,
			dots: false,
		});

		jQuery('[data-slider="features"]').on(
			"afterChange",
			function (event, slick, currentSlide, nextSlide) {
				jQuery(".amenities-features__list--item").removeClass(
					"amenities-features__list--item--active"
				);
				jQuery(`[data-id="${nextSlide}"]`).addClass(
					"amenities-features__list--item--active"
				);
			}
		);

		jQuery(".amenities-features__list--item").on("mouseover", function () {
			jQuery('[data-slider="features"]').slick(
				"slickGoTo",
				jQuery(this).data("id")
			);
		});



		AOS.init({
			useClassNames: true,
			offset: 100,
			disable: "mobile",
		});
	}
}

export { Amenities };
