import {
  Slick
} from "../slick";

class Home {
  constructor() {
    this.init();
  }

  init() {
    const { popUpTitle, popUpBody, popUpButtonText } = acfFields;

    const slickShow = new Slick("[data-slider='show']", {
      adaptiveHeight: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      dots: false,
      prevArrow: document.querySelector(".home-fifty__slide--prev"),
      nextArrow: document.querySelector(".home-fifty__slide--next"),
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          appendDots: ".home-fifty__right",
        },
      },],
    });

    jQuery('[data-slider="features"]').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      infinite: true,
      prevArrow: document.querySelector(".home-features__slide--prev"),
      nextArrow: document.querySelector(".home-features__slide--next"),
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          appendDots: ".home-features__left",
        },
      },],
    });

    jQuery('[data-slider="features"]').on(
      "beforeChange",
      function (event, slick, currentSlide, nextSlide) {
        jQuery(".home-features__list--item").removeClass(
          "home-features__list--item--active"
        );
        jQuery(`[data-id="${nextSlide}"]`).addClass(
          "home-features__list--item--active"
        );
      }
    );

    jQuery(".home-features__list--item").on("mouseover", function () {
      jQuery('[data-slider="features"]').slick(
        "slickGoTo",
        jQuery(this).data("id")
      );
    });

    // if (localStorage.getItem("popupWasShown") == null) {
    //   localStorage.setItem("popupWasShown", 1);
    //   basicLightbox
    //     .create(
    //       `
    //       <div class="lightbox-content lightbox-content--white">
    //         <div class="lightbox-content__header">
    //             <a class="close">Close</a>
    //         </div>
    //         <div class="lightbox-content__body">
    //             <h4>${popUpTitle}</h4>
    //             <p>${popUpBody}</p>
    //             <a class="btn close">${popUpButtonText}</a>
    //         </div>
    //       </div>  
    //   `, {
    //       onShow: (instance) => {

    //         instance.element().querySelectorAll(".close").forEach((close) => {
    //           close.onclick = () => instance.close();
    //         },)
    //       }
    //     }
    //     )
    //     .show();
    // }

    document.querySelectorAll("p:empty").forEach((x) => { x.remove() });

    AOS.init({
      useClassNames: true,
      offset: 100,
      disable: "mobile",
    });
  }
}

export {
  Home
};